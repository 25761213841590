import React, { FunctionComponent, useContext, useEffect } from "react";
import { ImageInterface } from "../../types/SanityTypes";
import SliderBlock from "../SliderBlock";
import { Container } from "react-bootstrap";
import { event11 } from "../../analytics/event11";
import { LocalizedContext } from "../../services/LocalizedContextService";
import ProductRatingsAndReviewScript from "../ProductRatingsAndReviewsScript";

import "swiper/scss";
import "./styles.scss";

export interface ProductSliderInterface {
  heading?: string;
  bgColor?: {
    value: string;
  };
  template?: string;
  content: {
    _id: string;
    _rawImages: ImageInterface[];
    title: string;
    url: string;
    _rawImage: ImageInterface;
    SubBrand: {
      name: string;
    };
    variant?: {
      primaryColor?: {
        hex?: string;
      };
    };
    name: string;
    displayReviews: boolean;
    slug: {
      current: string;
    };
    paUPC: string;
    paSmartProductId: string;
    // For Preview
    images?: ImageInterface[];
  }[];
}

const ProductSlider: FunctionComponent<ProductSliderInterface> = ({
  heading,
  bgColor,
  content,
  template = "product"
}) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);

  useEffect(() => {
    event11(content, sanitySiteSettings?.brandName, "Product Slider");
  }, [content, sanitySiteSettings?.brandName]);

  return (
    <section className="product-slider" data-testid="product-slider">
      <Container fluid>
        {heading && (
          <h2 className={`${template === "product" ? "rx-heading--medium" : ""}`} style={{ color: bgColor?.value }}>
            {heading.toUpperCase()}
          </h2>
        )}
        {content && <SliderBlock data={content as ProductSliderInterface["content"]} type={template} />}
      </Container>
      <ProductRatingsAndReviewScript />
    </section>
  );
};

export default ProductSlider;
